const server_url = process.env.REACT_APP_NEFRO_API_URL;

// peticiones de aplicación
const request = (endpoint, obj, method = 'GET') => {
    const url = `${server_url}/${endpoint}`;
    if (method === 'GET') {
        return fetch(url, { method });
    } else {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(obj)
        });
    }
};

export {
    request
};