import React, { useEffect, useState } from 'react'

//Router dom
import { useParams } from 'react-router-dom'

//Utils
import { CustomFetch } from '../../../Utils/CustomFetch'
import { ProductsData } from '../../../Data/ProductsData'

//Components
import ProductDetail from '../ProductDetail/ProductDetail'

const ProductDetailContainer = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const { name } = useParams()

    useEffect(() => {
        setLoading(false)
        CustomFetch(ProductsData)
            .then(res => {
                setData(res.find(listProducts => listProducts.name === name))
                setLoading(true)
            })
    }, [name])

    return (
        <>
            {
                loading
                    ?
                    <ProductDetail data={data} />
                    :
                    <>
                        Cargando...
                    </>
            }
        </>
    )
}

export default ProductDetailContainer