import React, { useState, useEffect } from 'react'

import '../Pov.css'

//Components
import Pov from '../Pov/Pov';

//MUI
import { Box, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const estadosArray = [
  "Aguascalientes", "Baja California", "Baja California Sur", "Campeche", "Chiapas", "Chihuahua", "Ciudad de México", "Coahuila", "Colima",
  "Durango", "Estado de México", "Guanajuato", "Guerrero", "Hidalgo", "Jalisco", "Michoacán", "Morelos", "Nayarit", "Nuevo León",
  "Oaxaca", "Puebla", "Querétaro", "Quintana Roo", "San Luis Potosí", "Sinaloa", "Sonora", "Tabasco", "Tamaulipas", "Tlaxcala", "Veracruz", "Yucatán", "Zacatecas",
]

const PovList = ({ data }) => {

  const [item, setItem] = useState(data)
  const [stateInput, setStateInput] = useState('');
  const [resultsFound, setResultsFound] = useState(true);

  const handleStateChange = (e) => {
    setStateInput(e.target.value)
  }

  const applyFilters = () => {
    let updatedList = data;

    if (stateInput) {
      updatedList = updatedList.filter(
        (item) =>
          item.state === stateInput
      );
    }

    setItem(updatedList);

    !updatedList.length ? setResultsFound(false) : setResultsFound(true);
  };

  useEffect(() => {
    applyFilters();
  }, [stateInput])

  const handleClear = () => {
    setItem(data)
    setStateInput('')
  }

  return (
    <>
      <Box className='pov_select'>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Seleccione su estado</InputLabel>
          <Select
            label='Seleccione su estado'
            value={stateInput}
            onChange={handleStateChange}
            displayEmpty
            sx={{
              "& .MuiSelect-iconOutlined": { display: stateInput ? 'none' : '', color: '#283B49' },
              "&.Mui-focused .MuiIconButton-root": { color: '#283B49' },
            }}
            endAdornment={<IconButton sx={{ visibility: stateInput ? "visible" : "hidden" }} onClick={handleClear}><ClearIcon /></IconButton>}
          >
            {
              estadosArray.map((res, index) =>
                <MenuItem key={index}
                  value={res}>
                  {res}
                </MenuItem>
              )
            }
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ px: 4, py: 2, border: '1px solid #283B49', borderRadius: '20px', boxShadow: 'rgba(40, 59, 73, 0.35) 0px 5px 15px' }}>
        <div className="pov_scroll">
          {
            resultsFound
              ?
              <>
                {
                  item.map((res) => (
                    <div key={res.id}>
                      <Pov res={res} />
                      <Divider sx={{ my: 2.5 }} />
                    </div>
                  ))
                }
              </>
              :
              <Box textAlign='center'>
                <ManageSearchIcon sx={{ fontSize: '40px' }} />
                <Typography variant='h5'>¡Lo sentimos! No se ha encontrado ningún resultado.</Typography>
                <Typography>No hemos podido encontrar lo que buscaba, Intente de nuevo.</Typography>
              </Box>
          }
        </div>
      </Box>
    </>
  )
}

export default PovList