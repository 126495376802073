import React, { useRef, useState } from 'react';

import './Footer.css'

//MUI
import { Box, Grid, Typography, Container, Stack, TextField, Button, FormHelperText } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/material/styles';

//Router dom
import { Link } from 'react-router-dom'
import { contactForm } from '../../Actions/form';

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    borderRadius: 4,
    backgroundColor: '#fff',
    border: '1px solid #fff',
    fontSize: 16,
  },
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#000',
  backgroundColor: '#fff',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: '#dbd9d9',
  },
}));

const initial = {
  name: '',
  email: '',
  phone: '',
  message: '',
};

const initErr = {
  name_err: '',
  email_err: '',
  phone_err: '',
  message_err: '',
};

const name_regex = /^([^0-9]*)$/;
const email_regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
const tel_regex = /^[0-9]*$/;

const Footer = ({ FormRef }) => {

  const [form, setForm] = useState(initial);
  const { name, email, phone, message } = form;

  const [inputErr, setInputErr] = useState(initErr);
  const { name_err, email_err, phone_err, message_err } = inputErr;

  const [loading, setLoading] = useState(false);

  const name_ref = useRef();
  const email_ref = useRef();
  const phone_ref = useRef();
  const message_ref = useRef();

  const onChange = ({ target }) => {
    const name = target.name
    const value = target.value
    if (name === 'name') {
      if (value === '' || name_regex.test(value)) {
        setForm(e => ({
          ...e,
          [name]: value
        })
        );
      }
    }
    else if (name === 'phone') {
      if (value === '' || tel_regex.test(value)) {
        setForm(e => ({
          ...e,
          [name]: value
        })
        );
      }
    }
    else setForm(e => ({
      ...e,
      [name]: value
    }));
    switch (name) {
      case 'name':
        setInputErr({
          ...inputErr,
          name_err: (value.length < 2 ||
            value.length > 100) ? true : false
        });
        break;
      case 'email':
        setInputErr({
          ...inputErr,
          email_err: (value.length < 2 ||
            value.length > 100) ? true : false
        });
        break;
      case 'phone':
        setInputErr({
          ...inputErr,
          phone_err: (value.length < 4 ||
            value.length > 50) ? true : false
        });
        break;
      case 'message':
        setInputErr({
          ...inputErr,
          message_err: (value.length < 2 ||
            value.length > 200) ? true : false
        });
        break;
      default:
        break;
    }
  };

  const formValidator = () => {
    const name_len = name.trim().length;
    if (name_len > 2 && name_len <= 100) {
      const email_len = email.trim().length;
      if (email_len > 2 && email_len <= 100 && email_regex.test(email)) {
        const phone_len = phone.trim().length
        if (phone_len > 4 && phone_len <= 50) {
          const message_len = message.trim().length;
          if (message_len > 2 && message_len <= 200) {
            return true
          } else {
            message_ref.current.select();
            setInputErr({
              ...inputErr,
              message_err: true
            });
          }
        }
        else {
          phone_ref.current.select();
          setInputErr({
            ...inputErr,
            phone_err: true
          });
        }
      } else {
        email_ref.current.select();
        setInputErr({
          ...inputErr,
          email_err: true
        });
      }
    } else {
      name_ref.current.select();
      setInputErr({
        ...inputErr,
        name_err: true
      });
    }
    return false;
  };

  const handleFormSubmit = async () => {
    if (formValidator()) {
      setLoading(true);
      const req = await contactForm(form);
      if (req.status)
        setForm(initial);
      setLoading(false);
    }
  };

  return (
    <>
      {/* Footer */}
      <footer ref={FormRef}>
        <Box>
          <Container maxWidth='lg' sx={{ my: 4 }}>
            {/* row */}
            <Grid container spacing={4}>
              {/* Formulario */}
              <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
                <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                  ¡Gana dinero distribuyendo nuestros productos!
                </Typography>
                <Typography sx={{ fontSize: '14px' }} gutterBottom>
                  Déjanos un mensaje y nos pondremos en contacto contigo para enviarte más información.
                </Typography>
                <Box sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CssTextField
                        placeholder='Nombre'
                        variant='outlined'
                        size='small'
                        fullWidth
                        name='name'
                        value={name}
                        onChange={onChange}
                        error={name_err}
                        inputRef={name_ref}
                        inputProps={{ maxLength: 100 }}
                      />
                      <FormHelperText sx={{ color: 'red' }}>{name_err ? "Ingrese su nombre" : ""}</FormHelperText>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <CssTextField
                        placeholder='Correo electrónico'
                        variant='outlined'
                        size='small'
                        fullWidth
                        name='email'
                        value={email}
                        onChange={onChange}
                        error={email_err}
                        inputRef={email_ref}
                        inputProps={{ maxLength: 100 }}
                      />
                      <FormHelperText sx={{ color: 'red' }}>{email_err ? "Correo electrónico invalido" : ""}</FormHelperText>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <CssTextField
                        placeholder='Número telefónico'
                        variant='outlined'
                        size='small'
                        fullWidth
                        name='phone'
                        value={phone}
                        onChange={onChange}
                        error={phone_err}
                        inputRef={phone_ref}
                        inputProps={{ maxLength: 100 }}
                      />
                      <FormHelperText sx={{ color: 'red' }}>{phone_err ? "Ingrese un número telefónico valido" : ""}</FormHelperText>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        placeholder='Mensaje'
                        variant='outlined'
                        size='small'
                        fullWidth
                        multiline
                        rows={4}
                        name='message'
                        value={message}
                        onChange={onChange}
                        error={message_err}
                        inputRef={message_ref}
                        inputProps={{ maxLength: 200 }}
                        style={{ backgroundColor: '#fff', borderRadius: 4 }}
                      />
                      <FormHelperText sx={{ color: 'red' }}>{message_err ? "Ingrese su mensaje" : ""}</FormHelperText>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      {
                        loading ?
                          <div id='load_button'>
                            <LoadingButton
                              endIcon={<SendIcon />}
                              loading={loading}
                              loadingPosition="end"
                              variant="contained"
                            >
                              <span>Enviando</span>
                            </LoadingButton>
                          </div>
                          :
                          <ColorButton variant='contained'
                            endIcon={<SendIcon />} onClick={handleFormSubmit}>
                            Enviar
                          </ColorButton>
                      }
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              {/* Contacto */}
              <Grid item xl={6} lg={6} md={4} sm={12} xs={12} sx={{ textAlign: { xl: 'right', lg: 'right', md: 'right', sm: 'left', xs: 'left' } }}>
                <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }} gutterBottom>
                  Enlaces
                </Typography>
                <Stack direction='column'>
                  <Link to='/aviso-de-privacidad' style={{ color: '#fff' }}>Aviso de privacidad</Link>
                  <Link to='/terminos-y-condiciones' style={{ color: '#fff' }}>Términos y condiciones</Link>
                </Stack>
                <Box sx={{ mt: 3 }}>
                  <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }} gutterBottom>
                    Contacto
                  </Typography>
                  <a href="mailto:contacto@nin.com.mx" style={{ color: '#fff' }}>contacto@nin.com.mx</a>
                  <Typography>WhatsApp. <a href="https://api.whatsapp.com/send?phone=523318011239&text=Hola,+quisiera+m%C3%A1s+informaci%C3%B3n+sobre+NIN" style={{ color: '#fff' }} target='_blank' rel="noopener noreferrer">33 1801 1239</a></Typography>
                </Box>
              </Grid>

            </Grid>
            {/* row */}
          </Container>
        </Box>

        {/* Copyright */}
        <Box sx={{ textAlign: 'center', p: 2.6, backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
          {new Date().getFullYear()} © Todos los derechos reservados.
        </Box>
        {/* Copyright */}
      </footer>
      {/* Footer */}
    </>
  )
}

export default Footer