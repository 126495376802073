import acetato_calcio from '../Assets/Products/Acetato_calcio/Acetato_de_calcio_1.0f4db30927db53f88515.jpg'
import acetato_calcio_1 from '../Assets/Products/Acetato_calcio/Acetato_de_calcio_2.bb7bbfad40d64b403d1a.jpg'
import acetato_calcio_2 from '../Assets/Products/Acetato_calcio/Acetato_de_calcio_3.2b07690f579039348728.jpg'
import acetato_calcio_3 from '../Assets/Products/Acetato_calcio/Acetato_de_calcio_atras.59e85113ad3ce788ed9d.jpg'

import hierro_liposomado from '../Assets/Products/Hierro_liposomado/hierro_oral_liposomado_1.525aba12fcbe39c2a403.jpg'
import hierro_liposomado_1 from '../Assets/Products/Hierro_liposomado/hierro_oral_liposomado_2.2068dc96efe16c66d3a1.jpg'
import hierro_liposomado_2 from '../Assets/Products/Hierro_liposomado/hierro_oral_liposomado_3.8904614e3e116fb8b82e.jpg'
import hierro_liposomado_3 from '../Assets/Products/Hierro_liposomado/hierro_oral_liposomado_4.1f839bef74b60e7d149a.jpg'

import galletas_avena from '../Assets/Products/Galletas_avena/galletas_avena_1.1fd125f7084bbd3761a6.jpg'
import galletas_avena_1 from '../Assets/Products/Galletas_avena/galletas_avena_2.6c99cc7bb248c99d2a72.jpg'

import albumina_1kg_cocoa from '../Assets/Products/Albumina_cocoa_1050/Albumina_cocoa_900g-1.b397c3e1c02321cce244.jpg'
import albumina_1kg_cocoa_1 from '../Assets/Products/Albumina_cocoa_1050/Albumina_cocoa_900g-2.1657e792e845f01a4b62.jpg'
import albumina_1kg_cocoa_2 from '../Assets/Products/Albumina_cocoa_1050/Albumina_cocoa_900g-3.8987ef9fed1518c461bb.jpg'

import albumina_900gr_natural from '../Assets/Products/Albumina_natural_900/Albumina_natural_900g-1.8ed8f75bc63718b0342a.jpg'
import albumina_900gr_natural_1 from '../Assets/Products/Albumina_natural_900/Albumina_natural_900g-2.f8bbb59671b011d525a2.jpg'
import albumina_900gr_natural_2 from '../Assets/Products/Albumina_natural_900/Albumina_natural_900g-3.dc78fe195b22a69353f7.jpg'

import simbin_120 from '../Assets/Products/Simbin_rnl_120/simbin_rnl_120caps_1.a985f711308cfb634e81.jpg'
import simbin_120_1 from '../Assets/Products/Simbin_rnl_120/simbin_rnl_120caps_2.08f72af141990aaeaf40.jpg'
import simbin_120_2 from '../Assets/Products/Simbin_rnl_120/simbin_rnl_120caps_3.c1b17a9f1e19481d3492.jpg'

import bicarbonato_500mg from '../Assets/Products/Bicarbontano_500/bicarbonato_500mg_1.df612f10dbdad8955187.jpg'
import bicarbonato_500mg_1 from '../Assets/Products/Bicarbontano_500/bicarbonato_500mg_2.b8021f4746d61c7e4a8f.jpg'
import bicarbonato_500mg_2 from '../Assets/Products/Bicarbontano_500/bicarbonato_500mg_3.e36ec9e1e050271dac13.jpg'

import malteada_mango_1kg from '../Assets/Products/Malteada_mango_1/Malteada-Nutritiva-mango-1.2kg_1.38d5541a233fe294eeca.jpg'
import malteada_mango_1kg_1 from '../Assets/Products/Malteada_mango_1/Malteada-Nutritiva-mango-1.2kg_2.f5733c5518a627c6d7f7.jpg'
import malteada_mango_1kg_2 from '../Assets/Products/Malteada_mango_1/Malteada-Nutritiva-mango-1.2kg_3.b4b5ac7b8bf70efc1e08.jpg'

import simbin_60 from '../Assets/Products/Simbin_rnl_60/simbin_rnl_60caps_1.313029460cec800efb38.jpg'
import simbin_60_1 from '../Assets/Products/Simbin_rnl_60/simbin_rnl_60caps_2.1011e9ba4005abba8907.jpg'
import simbin_60_2 from '../Assets/Products/Simbin_rnl_60/simbin_rnl_60caps_3.4ad6463af51b4192059f.jpg'

import bicarbonato_1000mg from '../Assets/Products/Bicarbonato_1000/bicarbonato_1000mg_1.47738722eca5fa73279e.jpg'
import bicarbonato_1000mg_1 from '../Assets/Products/Bicarbonato_1000/bicarbonato_1000mg_2.41e59487c7ff7a3b5d60.jpg'
import bicarbonato_1000mg_2 from '../Assets/Products/Bicarbonato_1000/bicarbonato_1000mg_3.ad708b991c44c2782490.jpg'

import albumina_350g_cocoa from '../Assets/Products/Albumina_cocoa_350/Albumina_cocoa_300g-1.3aeef5e5edf533b832cb.jpg'
import albumina_350g_cocoa_1 from '../Assets/Products/Albumina_cocoa_350/Albumina_cocoa_350g-2.5035519d3bb31dd13243.jpg'
import albumina_350g_cocoa_2 from '../Assets/Products/Albumina_cocoa_350/Albumina_cocoa_350g-3.b3c14e67171681b22207.jpg'

import malteada_mango_490g from '../Assets/Products/Malteada_mango_490/Malteada-Nutritiva-mango-490g_1.8d303cdf973e409b9154.jpg'
import malteada_mango_490g_1 from '../Assets/Products/Malteada_mango_490/Malteada-Nutritiva-mango-490g_2.0aa631ef146193754bf2.jpg'
import malteada_mango_490g_2 from '../Assets/Products/Malteada_mango_490/Malteada-Nutritiva-mango-490g_3.577e87cb9a491ea24f6d.jpg'

import bebida_alta_200g from '../Assets/Products/Bebida_alta_200/Bebida_arroz_alta_200g_1.9bab4fc666d1c1c4729d.jpg'
import bebida_alta_200g_1 from '../Assets/Products/Bebida_alta_200/Bebida_arroz_alta_200g_2.86b82b5e0ad668b0ade8.jpg'
import bebida_alta_200g_2 from '../Assets/Products/Bebida_alta_200/Bebida_arroz_alta_200g_3.72ce11fdc76554e04adf.jpg'

import malteada_platano_1kg from '../Assets/Products/Malteada_fresa_1/Malteada-Nutritiva-fresa-1.2kg_1.380cbe058b6fc1800316.jpg'
import malteada_platano_1kg_1 from '../Assets/Products/Malteada_fresa_1/Malteada-Nutritiva-fresa-1.2kg_2.7eb07615582931b13243.jpg'
import malteada_platano_1kg_2 from '../Assets/Products/Malteada_fresa_1/Malteada-Nutritiva-fresa-1.2kg_3.0382bbd9800154bbb7af.jpg'

import simbin_30 from '../Assets/Products/Simbin_rnl_30/simbin.6bd34c72bebc48079d76.jpg'
import simbin_30_1 from '../Assets/Products/Simbin_rnl_30/simbin_rnl_30caps_1.755992eb3fc852636eea.jpg'
import simbin_30_2 from '../Assets/Products/Simbin_rnl_30/simbin_rnl_30caps_2.afc961908a8fe1ef20ad.jpg'

import bebida_alta_600g from '../Assets/Products/Bebida_alta_600/Bebida_arroz_alta_600g_1.597aa8e35b2bfd38e086.jpg'
import bebida_alta_600g_1 from '../Assets/Products/Bebida_alta_600/Bebida_arroz_alta_600g_2.e6754b2504c16274ed99.jpg'
import bebida_alta_600g_2 from '../Assets/Products/Bebida_alta_600/Bebida_arroz_alta_600g_3.587239ab41e17710aebb.jpg'

import albumina_300g_natural from '../Assets/Products/Albumina_natural_350/Albumina_natural_350g-1.4f08a11ad9827a61a328.jpg'
import albumina_300g_natural_2 from '../Assets/Products/Albumina_natural_350/Albumina_natural_350g-2.44d8fdf310cea2745664.jpg'
import albumina_300g_natural_3 from '../Assets/Products/Albumina_natural_350/Albumina_natural_350g-3.6e7d308d96bbab016bb6.jpg'

import simbin_gel from '../Assets/Products/Simbin_gel/Simbin_gel_1.831902f3d982ae2fc779.jpg'
import simbin_gel_1 from '../Assets/Products/Simbin_gel/Simbin_gel_2.f4dd9fcdedd1f39679c2.jpg'
import simbin_gel_2 from '../Assets/Products/Simbin_gel/Simbin_gel_3.2dd49645fbd78d4ccf4f.jpg'
import simbin_gel_3 from '../Assets/Products/Simbin_gel/Simbin_gel_4.7975e1391bda59358d38.jpg'

import bebida_baja_200g from '../Assets/Products/Bebida_baja_200/Bebida_arroz_baja_200g_1.c35d8682760c2511db01.jpg'
import bebida_baja_200g_1 from '../Assets/Products/Bebida_baja_200/Bebida_arroz_baja_200g_2.d24137ba5c863c6df588.jpg'
import bebida_baja_200g_2 from '../Assets/Products/Bebida_baja_200/Bebida_arroz_baja_200g_3.cb1880344ddc36885444.jpg'

import malteada_platano_490g from '../Assets/Products/Malteada_fresa_490/Malteada-Nutritiva-fresa-490g_1.87e6b5a8a614bc2eae30.jpg'
import malteada_platano_490g_1 from '../Assets/Products/Malteada_fresa_490/Malteada-Nutritiva-fresa-490g_2.a4de62f9a5891120185b.jpg'
import malteada_platano_490g_2 from '../Assets/Products/Malteada_fresa_490/Malteada-Nutritiva-fresa-490g_3.885c40608a73d52e8bed.jpg'

import malteada_vegetal_1kg from '../Assets/Products/Malteada_vegetal_1/malteada_proteina_vegetal_1200g_1.e3ff67cf847845fdc300.jpg'
import malteada_vegetal_1kg_1 from '../Assets/Products/Malteada_vegetal_1/malteada_proteina_vegetal_1200g_2.aa5da44f80179a591058.jpg'
import malteada_vegetal_1kg_2 from '../Assets/Products/Malteada_vegetal_1/malteada_proteina_vegetal_1200g_3.218bc5b8a56f572c5649.jpg'

import bebida_baja_600g from '../Assets/Products/Bebida_baja_600/Bebida_arroz_baja_600g_1.efcc0d5c09939ffede03.jpg'
import bebida_baja_600g_1 from '../Assets/Products/Bebida_baja_600/Bebida_arroz_baja_600g_2.41cbceb6c4715cc9daeb.jpg'
import bebida_baja_600g_2 from '../Assets/Products/Bebida_baja_600/Bebida_arroz_baja_600g_3.4354645d3f0a02fa8837.jpg'

import malteada_vainilla_1kg from '../Assets/Products/Malteada_vainilla_1/Malteada-Nutritiva-vainilla-1.2kg_1.29135cc33f99d46a4320.jpg'
import malteada_vainilla_1kg_1 from '../Assets/Products/Malteada_vainilla_1/Malteada-Nutritiva-vainilla-1.2kg_2.8deb8e3f3259ed187d41.jpg'
import malteada_vainilla_1kg_2 from '../Assets/Products/Malteada_vainilla_1/Malteada-Nutritiva-vainilla-1.2kg_3.8c8e59fc035a2d0ab195.jpg'

import simbiotico from '../Assets/Products/Simbiotico/Simbiotico.8694991cd5857abb6109.jpg'
import simbiotico_1 from '../Assets/Products/Simbiotico/Simbiotico_2.463b05a21b4e53b2e24d.jpg'
import simbiotico_2 from '../Assets/Products/Simbiotico/Simbiotico_3.2c215ad72290e4c18928.jpg'
import simbiotico_3 from '../Assets/Products/Simbiotico/Simbiotico_4.ed1af01244df2325a49b.jpg'
import simbiotico_4 from '../Assets/Products/Simbiotico/Simbiotico_5.a70ea1e34809d35d5487.jpg'

import malteada_vegetal_600g from '../Assets/Products/Malteada_vegetal_600/malteada_proteina_vegetal_600g_1.a24522b63c035d581ade.jpg'
import malteada_vegetal_600g_1 from '../Assets/Products/Malteada_vegetal_600/malteada_proteina_vegetal_600g_2.df383e156c87e9cd806c.jpg'
import malteada_vegetal_600g_2 from '../Assets/Products/Malteada_vegetal_600/malteada_proteina_vegetal_600g_3.13f9b2afecf8e81ae845.jpg'

import bote_arandano from '../Assets/Products/Extracto_arandano/extracto_de_arandano.c51e7c3c19b000710b38.jpg'
import bote_arandano_1 from '../Assets/Products/Extracto_arandano/extracto_de_arandano_2.27e88eaac4b416f584e7.jpg'
import bote_arandano_2 from '../Assets/Products/Extracto_arandano/extracto_de_arandano_3.7101ed3825f2012dc070.jpg'
import bote_arandano_3 from '../Assets/Products/Extracto_arandano/extracto_de_arandano_4.a69e3f94c3357dcaf1cc.jpg'

import malteada_vainilla_490 from '../Assets/Products/Malteada_vainilla_490/Malteada-Nutritiva-vainilla-490g_1.fc0e6fcd3a2c6f53a24d.jpg'
import malteada_vainilla_490_1 from '../Assets/Products/Malteada_vainilla_490/Malteada-Nutritiva-vainilla-490g_2.830752f53f2335ed5f82.jpg'
import malteada_vainilla_490_2 from '../Assets/Products/Malteada_vainilla_490//Malteada-Nutritiva-vainilla-490g_3.14624e6aa6e15ede9ecc.jpg'

import inulina from '../Assets/Products/Inulina_agave/inulina_agave_1.6906690d9bdea068bbc0.jpg'
import inulina_1 from '../Assets/Products/Inulina_agave/inulina_agave_2.b75610c94ddfd0844bb1.jpg'
import inulina_2 from '../Assets/Products/Inulina_agave/inulina_agave_3.2402a0ed0b44ba2f7f31.jpg'
import inulina_3 from '../Assets/Products/Inulina_agave/inulina_agave_4.7a04cec426fd26add881.jpg'
import inulina_4 from '../Assets/Products/Inulina_agave/inulina_agave_5.1aee439008bc3a05fec7.jpg'

import multi from '../Assets/Products/Multivitaminico/Multivitaminico_nin_1.b5ca76ac68c454eaf687.jpg'
import multi_1 from '../Assets/Products/Multivitaminico/Multivitaminico_nin_2.6878cf0e5ce56d73ced7.jpg'
import multi_2 from '../Assets/Products/Multivitaminico/Multivitaminico_nin_3.5146c65bd91faa80e1df.jpg'
import multi_3 from '../Assets/Products/Multivitaminico/Multivitaminico_nin_4.1e55ff29d33778d76ee8.jpg'

export const ProductsData = [
    {
        id: 1,
        name: 'Acetato de calcio nin',
        description: [
            'El Acetato de Calcio NIN® es un suplemento alimenticio especializado para personas que viven con enfermedad renal, este suplemento fue pensado para funcionar como captor de fósforo, participando de esta manera en el control de los niveles de fósforo en sangre.'
        ],
        price: 0,
        ingredients: [
            'Acetato de Calcio',
            'Estearato de Magnesio'
        ],
        presentation: [
            'Frasco de plástico con 90 tabletas de 672 mg.'
        ],
        netContent: [
            '672 mg'
        ],
        principal_img: acetato_calcio,
        images: [
            {
                img: acetato_calcio,
            },
            {
                img: acetato_calcio_1,
            },
            {
                img: acetato_calcio_2,
            },
            {
                img: acetato_calcio_3,
            },
        ],
        link: 'https://www.nefropolis.com/tienda/producto/acetato-de-calcio-nin?variant=7503018606194',
    },
    {
        id: 2,
        name: 'Hierro liposomado 500 ml',
        description: [
            'El Hierro Liposomado NIN®. es un suplemento alimenticio, su fórmula está fortificada con vitamina B12 y ácido fólico, elaborado mediante nanotecnología liposomal con lo que se garantiza una mayor absorción, así como la disminución de síntomas gastrointestinales propios de la suplementación del mineral. Elaborado y enfocado para el consumo de personas que viven con Enfermedad Renal Crónica, ayudando a disminuir el cansancio y la fatiga.'
        ],
        price: 0,
        ingredients: [
            'Agua purificada',
            'licerina',
            'saborizantes naturales y artificiales',
            'triglicéridos de cadena media',
            'sulfato ferroso, sorbato de potasio',
            'stevia',
            'ácido cítrico',
            'extracto de hoja de olivo',
            'colorante(FD & C Rojo no. 40)',
            'sucralosa',
            'metilcobalamina(vitamina B12)'
        ],
        presentation: [
            'Frasco de plástico de 500 ml. (16.9 Oz), sabor frutos rojos.'
        ],
        netContent: [
            '500 ml'
        ],
        principal_img: hierro_liposomado,
        images: [
            {
                img: hierro_liposomado,
            },
            {
                img: hierro_liposomado_1,
            },
            {
                img: hierro_liposomado_2,
            },
            {
                img: hierro_liposomado_3,
            },
        ],
        link: 'https://www.nefropolis.com/tienda/producto/hierro-liposomado-nin-500-ml?variant=7503018606941'
    },
    {
        id: 3,
        name: 'Galletas de avena NIN',
        description: [
            'Las Galletas de avena NIN® son una alternativa deliciosa y nutritiva como snack, para satisfacer el paladar de personas con enfermedad renal crónica en cualquier etapa, al mismo tiempo que cuidan la salud del paciente gracias a que son elaboradas de forma artesanal, a base de harina de trigo y hojuelas de avena, ofrecen un bajo aporte de sodio, fósforo y potasio.'
        ],
        price: 0,
        ingredients: [
            'Harina de trigo',
            'avena en hojuelas',
            'clara de huevo',
            'amaranto, aceite de canola',
            'semilla de girasol',
            'nuez',
            'mantequilla',
            'inulina de agave',
            'arándano deshidratado',
            'azúcares añadidos',
            'miel de agave(azúcares añadidos)',
            'sucralosa(42 mg / 100 g)',
            'polvo para hornear'
        ],
        presentation: [
            '3 porciones por envase. (90 gr).'
        ],
        netContent: [
            '90 g'
        ],
        principal_img: galletas_avena,
        images: [
            {
                img: galletas_avena,
            },
            {
                img: galletas_avena_1,
            }
        ],
        link: 'https://www.nefropolis.com/tienda/producto/galletas-de-avena-nin?variant=7503018606293'
    },
    {
        id: 4,
        name: 'Albúmina de huevo sabor cocoa 1050g',
        description: [
            'La albúmina de huevo sabor cocoa NIN® es un suplemento alimenticio con alto contenido proteico extraído de la clara de huevo. Este producto es apto para pacientes que viven con enfermedad renal crónica en etapa de diálisis y hemodiálisis.'
        ],
        price: 0,
        ingredients: [
            'Albúmina de huevo',
            'fructanos de agave',
            'maltodextrina',
            'cocoa',
            'edulcorante artificial(sucralosa 31.7 mg / 100 g)',
            'saborizantes artificiales',
            'lecitina de soya',
            'almidón',
            'goma xantana',
            'goma guar'
        ],
        presentation: [],
        netContent: [
            '1050 g'
        ],
        principal_img: albumina_1kg_cocoa,
        images: [
            {
                img: albumina_1kg_cocoa,
            },
            {
                img: albumina_1kg_cocoa_1,
            },
            {
                img: albumina_1kg_cocoa_2,
            },
        ],
        link: 'https://www.nefropolis.com/tienda/producto/albumina-de-huevo-nin-cocoa-1050g?variant=7503018606651'
    },
    {
        id: 5,
        name: 'Simbin rnl 120 caps',
        description: [
            'Suplemento alimenticio adicionado con prebióticos y probióticos de cepas específicas para el tratamiento de los síntomas gastrointestinales de la enfermedad renal crónica, dichas cepas se destacan por su efecto positivo de mantener un equilibrio en la microbiota intestinal así como reducir las toxinas urémicas comunes en ERC.'
        ],
        price: 0,
        ingredients: [
            'Inulina de agave',
            'S. thermophilus',
            'L. acidophilus',
            'B. longum',
            'maltodextrina',
            'estearato de magnésio',
            'dióxido de silício'
        ],
        presentation: [
            '30, 60 y 120 Cápsulas',
        ],
        netContent: [
            '540 mg',
        ],
        principal_img: simbin_120,
        images: [
            {
                img: simbin_120,
            },
            {
                img: simbin_120_1,
            },
            {
                img: simbin_120_2,
            }
        ],
        link: 'https://www.nefropolis.com/tienda/producto/simbin-rnl-120-caps?variant=7503035883141'
    },
    {
        id: 6,
        name: 'Albúmina de huevo natural 900g',
        description: [
            'La albúmina de huevo natural NIN® es un suplemento alimenticio con alto contenido proteico extraído de la clara de huevo. Este producto es apto para pacientes que viven con enfermedad renal crónica en etapa de diálisis y hemodiálisis.'
        ],
        price: 0,
        ingredients: [
            'Albúmina de huevo',
            'Maltodextrina',
            'Lecitina de soya',
            'maltodextrina'
        ],
        presentation: [
            'Tarro con 900 g de producto con 30 porciones'
        ],
        netContent: [
            '900 g',
        ],
        principal_img: albumina_900gr_natural,
        images: [
            {
                img: albumina_900gr_natural,
            },
            {
                img: albumina_900gr_natural_1,
            },
            {
                img: albumina_900gr_natural_2,
            },
        ],
        link: 'https://www.nefropolis.com/tienda/producto/albumina-de-huevo-nin-900g?variant=7503018606644'
    },
    {
        id: 7,
        name: 'Bicarbonato de sodio nin 500mg',
        description: [
            'Suplemento alimenticio elaborado para actuar en presencia de acidosis metabólica, o alteraciones por deficiencias de bicarbonato sérico, en la enfermedad renal crónica. Contribuye al tratamiento y regula los valores séricos, evitando estados catabólicos en el organismo. Ideal para la etapa de diálisis y hemodiálisis.'
        ],
        price: 0,
        ingredients: [
            'Bicarbonato de sodio'
        ],
        presentation: [
            'Frasco con 60 cápsulas (500 mg)',
        ],
        netContent: [
            '500 mg',
        ],
        principal_img: bicarbonato_500mg,
        images: [
            {
                img: bicarbonato_500mg,
            },
            {
                img: bicarbonato_500mg_1,
            },
            {
                img: bicarbonato_500mg_2,
            },
        ],
        link: 'https://www.nefropolis.com/tienda/producto/bicarbonato-de-sodio-nin-500mg?variant=7503018606231'
    },
    {
        id: 8,
        name: 'Malteada nutritiva sabor mango 1.2kg',
        description: [
            'La malteada nutritiva NIN® es un suplemento alimenticio completo, hipercalórico por nutriólogos especializados en la enfermedad renal crónica. Por sus bajos aportes de minerales y proteínas, su consumo es ideal en la etapa de prediálisis, contribuyendo a mejorar el estado de nutrición de los pacientes con ERC.'
        ],
        price: 0,
        ingredients: [
            'Aceite de canola',
            'proteína aislada de soya',
            'almidón',
            'maltodextrina',
            'aceite de palma',
            'fructanos de agave',
            'fibra de avena',
            'saborizante',
            'mezcla vitamínica [maltodextrina]',
            'vitamina C',
            'zinc',
            'niacina(vitamina B3)',
            'piridoxina(vitamina B6)',
            'hierro',
            'tiamina(vitamina B1)',
            'ácido fólico(vitamina B9)',
            'vitamina D3',
            'Cianocobalamina(vitamina B12)] goma xantana',
            'colorante amarillo huevo',
            'sucralosa(5 mg/ 100g)',
            'Bifidobacterium longum'
        ],
        presentation: [
            'Mango 1200 g (17,1 porciones)',
        ],
        netContent: [
            '1200 g',
        ],
        principal_img: malteada_mango_1kg,
        images: [
            {
                img: malteada_mango_1kg,
            },
            {
                img: malteada_mango_1kg_1,
            },
            {
                img: malteada_mango_1kg_2,
            }
        ],
        link: 'https://www.nefropolis.com/tienda/producto/malteada-nutritiva-nin-mango-1-2kg?variant=7503035883028'
    },
    {
        id: 9,
        name: 'Simbin rnl 60 caps',
        description: [
            'Suplemento alimenticio adicionado con prebióticos y probióticos de cepas específicas para el tratamiento de los síntomas gastrointestinales de la enfermedad renal crónica, dichas cepas se destacan por su efecto positivo de mantener un equilibrio en la microbiota intestinal así como reducir las toxinas urémicas comunes en ERC.'
        ],
        price: 0,
        ingredients: [
            'Inulina de agave',
            'S. thermophilus',
            'L. acidophilus',
            'B. longum',
            'maltodextrina',
            'estearato de magnésio',
            'dióxido de silício'
        ],
        presentation: [
            '30, 60 y 120 Cápsulas',
        ],
        netContent: [
            '540 mg',
        ],
        principal_img: simbin_60,
        images: [
            {
                img: simbin_60,
            },
            {
                img: simbin_60_1,
            },
            {
                img: simbin_60_2,
            }
        ],
        link: 'https://www.nefropolis.com/tienda/producto/simbin-rnl-60-caps?variant=7503018606323'
    },
    {
        id: 10,
        name: 'Bicarbonato de sodio nin 1000mg',
        description: [
            'Suplemento alimenticio elaborado para actuar en presencia de acidosis metabólica, o alteraciones por deficiencias de bicarbonato sérico, en la enfermedad renal crónica. Contribuye al tratamiento y regula los valores séricos, evitando estados catabólicos en el organismo. Ideal para la etapa de diálisis y hemodiálisis.'
        ],
        price: 0,
        ingredients: [
            'Bicarbonato de sodio'
        ],
        presentation: [
            'Frasco con 60 cápsulas (1000 mg)',
        ],
        netContent: [
            '1000 mg',
        ],
        principal_img: bicarbonato_1000mg,
        images: [
            {
                img: bicarbonato_1000mg,
            },
            {
                img: bicarbonato_1000mg_1,
            },
            {
                img: bicarbonato_1000mg_2,
            },
        ],
        link: 'https://www.nefropolis.com/tienda/producto/bicarbonato-de-sodio-nin-1000mg?variant=7503018606224'
    },
    {
        id: 11,
        name: 'Albúmina de huevo sabor cocoa 350g',
        description: [
            'La albúmina de huevo sabor cocoa NIN® es un suplemento alimenticio con alto contenido proteico extraído de la clara de huevo. Este producto es apto para pacientes que viven con enfermedad renal crónica en etapa de diálisis y hemodiálisis.'
        ],
        price: 0,
        ingredients: [
            'Albúmina de huevo',
            'fructanos de agave',
            'maltodextrina',
            'cocoa',
            'edulcorante artificial(sucralosa 31.7 mg / 100 g)',
            'saborizantes artificiales',
            'lecitina de soya',
            'almidón',
            'goma xantana',
            'goma guar'
        ],
        presentation: [],
        netContent: [
            '350 g',
        ],
        principal_img: albumina_350g_cocoa,
        images: [
            {
                img: albumina_350g_cocoa,
            },
            {
                img: albumina_350g_cocoa_1,
            },
            {
                img: albumina_350g_cocoa_2,
            },
        ],
        link: 'https://www.nefropolis.com/tienda/producto/albumina-de-huevo-nin-cocoa-350g?variant=7503018606354'
    },
    {
        id: 12,
        name: 'Malteada nutritiva sabor mango 490g',
        description: [
            'La malteada nutritiva NIN® es un suplemento alimenticio completo, hipercalórico por nutriólogos especializados en la enfermedad renal crónica. Por sus bajos aportes de minerales y proteínas, su consumo es ideal en la etapa de prediálisis, contribuyendo a mejorar el estado de nutrición de los pacientes con ERC.'
        ],
        price: 0,
        ingredients: [
            'Aceite de canola',
            'proteína aislada de soya',
            'almidón',
            'maltodextrina',
            'aceite de palma',
            'fructanos de agave',
            'fibra de avena',
            'saborizante',
            'mezcla vitamínica [maltodextrina]',
            'vitamina C',
            'zinc',
            'niacina(vitamina B3)',
            'piridoxina(vitamina B6)',
            'hierro',
            'tiamina(vitamina B1)',
            'ácido fólico(vitamina B9)',
            'vitamina D3',
            'Cianocobalamina(vitamina B12)] goma xantana',
            'colorante amarillo huevo',
            'sucralosa(5 mg/ 100g)',
            'Bifidobacterium longum'
        ],
        presentation: [
            'Mango 490 g de producto (7 porciones.)',
        ],
        netContent: [
            '490 g',
        ],
        principal_img: malteada_mango_490g,
        images: [
            {
                img: malteada_mango_490g,
            },
            {
                img: malteada_mango_490g_1,
            },
            {
                img: malteada_mango_490g_2,
            }
        ],
        link: 'https://www.nefropolis.com/tienda/producto/malteada-nutritiva-nin-mango-490g?variant=7503035883011'
    },
    {
        id: 13,
        name: 'Bebida de arroz, soya y almendras alta en proteína 200g',
        description: [
            'La Bebida de Arroz, Soya y Almendra NIN® alta en proteína es una bebida nutritiva pensada para sustituir el consumo de leche de vaca en la dieta de personas que viven con enfermedad renal crónica y que reciben una terapia de diálisis, ya que cuenta con valores nutricionales similares a los de la leche de origen animal, al combinar 3 proteínas vegetales pero con un menor contenido de sodio, fósforo y potasio.'
        ],
        price: 0,
        ingredients: [
            'Proteína aislada de soya',
            'grasa de canola (encapsulante, grasa de canola y maltodextrina)',
            'proteína de arroz',
            'harina de arroz',
            'lactomina',
            'grasa de palma (aceite vegetal de palma, mono y diglicéridos de ácidos grasos, saborizante artificial, sales funcionales, caseinato de sodio, maltodextrina, antiapelmazante, antioxidante BHT - BHA)',
            'almendra en trozos',
            'saborizantes artificiales',
            'goma guar',
            'goma xantana',
            'sucralosa (200 mg por cada 100 gr de producto. Contiene soya, almendras y caseinato de sodio (leche)',
            'Puede contener albúmina (huevo)'
        ],
        presentation: [
            'Bolsa pouch, trilaminada, resellable con 200 g de producto con 10 porciones.',
        ],
        netContent: [
            '200 g',
        ],
        principal_img: bebida_alta_200g,
        images: [
            {
                img: bebida_alta_200g,
            },
            {
                img: bebida_alta_200g_1,
            },
            {
                img: bebida_alta_200g_2,
            }
        ],
        link: 'https://www.nefropolis.com/tienda/producto/bebida-de-arroz-nin-200g-dialisis-alta-proteina?variant=7503018606101'
    },
    {
        id: 14,
        name: 'Malteada nutritiva sabor platano-fresa 1.2kg',
        description: [
            'La malteada nutritiva NIN® es un suplemento alimenticio completo, hipercalórico por nutriólogos especializados en la enfermedad renal crónica. Por sus bajos aportes de minerales y proteínas, su consumo es ideal en la etapa de prediálisis, contribuyendo a mejorar el estado de nutrición de los pacientes con ERC.'
        ],
        price: 0,
        ingredients: [
            'Aceite de canola',
            'proteína aislada de soya',
            'almidón',
            'maltodextrina',
            'aceite de palma',
            'fructanos de agave',
            'fibra de avena',
            'saborizantes',
            'carbonato de magnesio',
            'mezcla vitamínica[maltodextrina, vitamina C, zinc, niacina(vitamina B3)',
            'piridoxina(vitamina B6)',
            'hierro',
            'tiamina(vitamina B1)',
            'ácido fólico(vitamina B9)',
            'vitamina D3',
            'Cianocobalamina(vitamina B12)]',
            'goma xantana',
            'sucralosa(5 mg/ 100g)',
            'Bifidobacterium longum',
            'color rojo allura'
        ],
        presentation: [
            'Plátano-Fresa 1200 g (17,1 porciones)',
        ],
        netContent: [
            '1200 g',
        ],
        principal_img: malteada_platano_1kg,
        images: [
            {
                img: malteada_platano_1kg,
            },
            {
                img: malteada_platano_1kg_1,
            },
            {
                img: malteada_platano_1kg_2,
            }
        ],
        link: 'https://www.nefropolis.com/tienda/producto/malteada-nutritiva-nin-platano-fresa-1-2kg?variant=7503035883004'
    },
    {
        id: 15,
        name: 'Simbin rnl 30 caps',
        description: [
            'Suplemento alimenticio adicionado con prebióticos y probióticos de cepas específicas para el tratamiento de los síntomas gastrointestinales de la enfermedad renal crónica, dichas cepas se destacan por su efecto positivo de mantener un equilibrio en la microbiota intestinal así como reducir las toxinas urémicas comunes en ERC.'
        ],
        price: 0,
        ingredients: [
            'Inulina de agave',
            'S. thermophilus',
            'L. acidophilus',
            'B. longum',
            'maltodextrina',
            'estearato de magnésio',
            'dióxido de silício'
        ],
        presentation: [
            '30, 60 y 120 Cápsulas',
        ],
        netContent: [
            '540 mg',
        ],
        principal_img: simbin_30,
        images: [
            {
                img: simbin_30,
            },
            {
                img: simbin_30_1,
            },
            {
                img: simbin_30_2,
            },
        ],
        link: 'https://www.nefropolis.com/tienda/producto/simbin-rnl-30-caps?variant=7503035883288'
    },
    {
        id: 16,
        name: 'Bebida de arroz, soya y almendras alta en proteína 600g',
        description: [
            'La Bebida de Arroz, Soya y Almendra NIN® alta en proteína es una bebida nutritiva pensada para sustituir el consumo de leche de vaca en la dieta de personas que viven con enfermedad renal crónica y que reciben una terapia de diálisis, ya que cuenta con valores nutricionales similares a los de la leche de origen animal, al combinar 3 proteínas vegetales pero con un menor contenido de sodio, fósforo y potasio.'
        ],
        price: 0,
        ingredients: [
            'Proteína aislada de soya',
            'grasa de canola (encapsulante, grasa de canola y maltodextrina)',
            'proteína de arroz',
            'harina de arroz',
            'lactomina',
            'grasa de palma (aceite vegetal de palma, mono y diglicéridos de ácidos grasos, saborizante artificial, sales funcionales, caseinato de sodio, maltodextrina, antiapelmazante, antioxidante BHT - BHA)',
            'almendra en trozos',
            'saborizantes artificiales',
            'goma guar',
            'goma xantana',
            'sucralosa (200 mg por cada 100 gr de producto. Contiene soya, almendras y caseinato de sodio (leche)',
            'Puede contener albúmina (huevo)'
        ],
        presentation: [
            'Bolsa pouch, trilaminada, resellable con 600 g de producto con 30 porciones.',
        ],
        netContent: [
            '600 g',
        ],
        principal_img: bebida_alta_600g,
        images: [
            {
                img: bebida_alta_600g,
            },
            {
                img: bebida_alta_600g_1,
            },
            {
                img: bebida_alta_600g_2,
            },
        ],
        link: 'https://www.nefropolis.com/tienda/producto/bebida-de-arroz-nin-600g-dialisis-alta-proteina?variant=7503018606972'
    },
    {
        id: 17,
        name: 'Albúmina de huevo natural 300g',
        description: [
            'La albúmina de huevo natural NIN® es un suplemento alimenticio con alto contenido proteico extraído de la clara de huevo. Este producto es apto para pacientes que viven con enfermedad renal crónica en etapa de diálisis y hemodiálisis.'
        ],
        price: 0,
        ingredients: [
            'Albúmina de huevo',
            'Maltodextrina',
            'Lecitina de soya',
            'maltodextrina'
        ],
        presentation: [
            'Tarro con 300 g de producto con 10 porciones.'
        ],
        netContent: [
            '300 g',
        ],
        principal_img: albumina_300g_natural,
        images: [
            {
                img: albumina_300g_natural,
            },
            {
                img: albumina_300g_natural_2,
            },
            {
                img: albumina_300g_natural_3,
            },
        ],
        link: 'https://www.nefropolis.com/tienda/producto/albumina-de-huevo-nin-300g?variant=7503018606286'
    },
    {
        id: 18,
        name: 'Simbin rnl gel +',
        description: [
            'El Probiótico SIMBIN GEL® es un suplemento alimenticio de consumo diario especializado para personas que viven con ERC, contiene microorganismos probióticos en su formulación, los cuales son considerados benéficos para la microbiota intestinal, generando la disminución de los problemas de tránsito intestinal, evitando el establecimiento de bacterias dañinas, así como la presencia de síntomas gastrointestinales.'
        ],
        price: 0,
        ingredients: [
            'Agua',
            'inulina de agave',
            'fibra soluble de maíz',
            'betaglucanos de avena',
            'sucralosa(2.5 g / 100g de producto)',
            'mezcla probiótica(S.thermophilus, L.acidophilus, B.longum)',
            'aceite de aguacate',
            'saborizante artificial',
            'ácido sórbico',
            'ácido cítrico'
        ],
        presentation: [
            'Caja con 15 sobres de 40 g.'
        ],
        netContent: [
            '600 g',
        ],
        principal_img: simbin_gel,
        images: [
            {
                img: simbin_gel,
            },
            {
                img: simbin_gel_1,
            },
            {
                img: simbin_gel_2,
            },
            {
                img: simbin_gel_3,
            }
        ],
        link: 'https://www.nefropolis.com/tienda/producto/simbin-rnl-gel-+?variant=7503018606934'
    },
    {
        id: 19,
        name: 'Bebida de arroz, soya y almendras baja en proteína 200g',
        description: [
            'Polvo de diferentes proteínas vegetales: arroz, soya y almendras, para preparar bebida baja en proteínas, especial para el paciente con enfermedad renal crónica en etapa de prediálisis debido a que aporta bajo contenido de sodio, potasio, y fósforo.'
        ],
        price: 0,
        ingredients: [
            'Aceite de canola(encapsulante, aceite de canola y maltodextrina)',
            'aceite de palma(aceite vegetal de palma, mono y diglicéridos de ácidos grasos, saborizante artificial, sales funcionales, caseinato de sodio, maltodextrina, antiapelmazante, antioxidante BHT - BHA)',
            'harina de arroz',
            'lactomina',
            'maltodextrina',
            'proteína aislada de soya',
            'proteína aislada de arroz',
            'almendra en trozos',
            'saborizantes artificiales',
            'goma guar',
            'goma xantana',
            'sucralosa(150 mg por cada 100 g de producto)',
            'Contiene soya, almendras y caseinato de sodio(leche)',
            'Puede contener albúmina (huevo)'
        ],
        presentation: [
            'Bolsa de 200 g rinde 10 porciones'
        ],
        netContent: [
            '200 g',
        ],
        principal_img: bebida_baja_200g,
        images: [
            {
                img: bebida_baja_200g,
            },
            {
                img: bebida_baja_200g_1,
            },
            {
                img: bebida_baja_200g_2,
            }
        ],
        link: 'https://www.nefropolis.com/tienda/producto/bebida-de-arroz-nin-200g-baja-proteina?variant=7503018606095'
    },
    {
        id: 20,
        name: 'Malteada nutritiva sabor platano-fresa 490g',
        description: [
            'La malteada nutritiva NIN® es un suplemento alimenticio completo, hipercalórico por nutriólogos especializados en la enfermedad renal crónica. Por sus bajos aportes de minerales y proteínas, su consumo es ideal en la etapa de prediálisis, contribuyendo a mejorar el estado de nutrición de los pacientes con ERC.'
        ],
        price: 0,
        ingredients: [
            'Aceite de canola',
            'proteína aislada de soya',
            'almidón',
            'maltodextrina',
            'aceite de palma',
            'fructanos de agave',
            'fibra de avena',
            'saborizantes',
            'carbonato de magnesio',
            'mezcla vitamínica[maltodextrina, vitamina C, zinc, niacina(vitamina B3)',
            'piridoxina(vitamina B6)',
            'hierro',
            'tiamina(vitamina B1)',
            'ácido fólico(vitamina B9)',
            'vitamina D3',
            'Cianocobalamina(vitamina B12)]',
            'goma xantana',
            'sucralosa(5 mg/ 100g)',
            'Bifidobacterium longum',
            'color rojo allura'
        ],
        presentation: [
            'Plátano-Fresa 490 g (7 porciones)',
        ],
        netContent: [
            '490 g',
        ],
        principal_img: malteada_platano_490g,
        images: [
            {
                img: malteada_platano_490g,
            },
            {
                img: malteada_platano_490g_1,
            },
            {
                img: malteada_platano_490g_2,
            }
        ],
        link: 'https://www.nefropolis.com/tienda/producto/malteada-nutritiva-nin-platano-fresa-490g?variant=7503018606996'
    },
    {
        id: 21,
        name: 'Malteada de proteina vegetal sabor fresa 1.2kg',
        description: [
            'La Malteada de proteína vegetal NIN® es un suplemento alimenticio con alto aporte de proteína de origen vegetal, así como de energía (Kcals), adicionada con inulina de agave, omega 3, vitaminas y minerales, diseñado especialmente para el consumo de personas con enfermedad renal crónica (ERC) en etapa de diálisis, sabor fresa.'
        ],
        price: 0,
        ingredients: [
            'Grasa de canola',
            'maltodextrina',
            'proteína de arroz',
            'proteína de soya',
            'grasa de palma',
            'proteína de chicharo',
            'inulina de agave',
            'saborizantes artificiales',
            'espenzante',
            'omega 3',
            'carbonato de magnesio',
            'mezcla multivitamínica(maltodextrina, ascobarto de sodio(vitamina C)',
            'sulfato de zinc',
            'niacinamida(vitamina B3)',
            'piridoxina clorhidrato(vitamina B6)',
            'pirofisfato férrico monohidratado de tiamina(vitamina B1)',
            'ácido fólico',
            '(vitamina B9)',
            'selenio de sodio',
            'ergocalciferol',
            '(vitamina D2)',
            'clanocobalamina(vitamina B12)',
            'sucralosa(30mg / 100g de producto)',
            'goma xantana',
            'colorante(rojo 40)',
            'sucralosa / 18 mg / 100 g'
        ],
        presentation: [
            'Frasco con polvo de 1,200 g',
        ],
        netContent: [
            '1200 g',
        ],
        principal_img: malteada_vegetal_1kg,
        images: [
            {
                img: malteada_vegetal_1kg,
            },
            {
                img: malteada_vegetal_1kg_1,
            },
            {
                img: malteada_vegetal_1kg_2,
            },
        ],
        link: 'https://www.nefropolis.com/tienda/producto/proteina-vegetal-nin-fresa-1-2-kg?variant=7503018606361'
    },
    {
        id: 22,
        name: 'Bebida de arroz, soya y almendras baja en proteína 600g',
        description: [
            'Polvo de diferentes proteínas vegetales: arroz, soya y almendras, para preparar bebida baja en proteínas, especial para el paciente con enfermedad renal crónica en etapa de prediálisis debido a que aporta bajo contenido de sodio, potasio, y fósforo.'
        ],
        price: 0,
        ingredients: [
            'Aceite de canola(encapsulante, aceite de canola y maltodextrina)',
            'aceite de palma(aceite vegetal de palma, mono y diglicéridos de ácidos grasos, saborizante artificial, sales funcionales, caseinato de sodio, maltodextrina, antiapelmazante, antioxidante BHT - BHA)',
            'harina de arroz',
            'lactomina',
            'maltodextrina',
            'proteína aislada de soya',
            'proteína aislada de arroz',
            'almendra en trozos',
            'saborizantes artificiales',
            'goma guar',
            'goma xantana',
            'sucralosa(150 mg por cada 100 g de producto)',
            'Contiene soya, almendras y caseinato de sodio(leche)',
            'Puede contener albúmina (huevo)'
        ],
        presentation: [
            'Bolsa de 600 g rinde 30 porciones'
        ],
        netContent: [
            '600 g',
        ],
        principal_img: bebida_baja_600g,
        images: [
            {
                img: bebida_baja_600g,
            },
            {
                img: bebida_baja_600g_1,
            },
            {
                img: bebida_baja_600g_2,
            },
        ],
        link: 'https://www.nefropolis.com/tienda/producto/bebida-de-arroz-nin-600g-baja-proteina?variant=7503018606989'
    },
    {
        id: 23,
        name: 'Malteada nutritiva sabor vainilla 1.2kg',
        description: [
            'La malteada nutritiva NIN® es un suplemento alimenticio completo, hipercalórico por nutriólogos especializados en la enfermedad renal crónica. Por sus bajos aportes de minerales y proteínas, su consumo es ideal en la etapa de prediálisis, contribuyendo a mejorar el estado de nutrición de los pacientes con ERC.'
        ],
        price: 0,
        ingredients: [
            'Aceite de canola',
            'proteína aislada de soya',
            'almidón',
            'saborizantes',
            'aceite de palma',
            'fructanos de agave',
            'fibra de avena',
            'maltodextrina',
            'mezcla vitamínica[maltodextrina, vitamina C, zinc, niacina(vitamina B3), piridoxina(vitamina B6), hierro, tiamina(vitamina B1), ácido fólico(vitamina B9), vitamina D3, Cianocobalamina(vitamina B12)]',
            'goma xantana',
            'sucralosa(5 mg/ 100g)',
            'Bifidobacterium longum'
        ],
        presentation: [
            'Vainilla 1200 g (17,1 porciones)',
        ],
        netContent: [
            '1200 g',
        ],
        principal_img: malteada_vainilla_1kg,
        images: [
            {
                img: malteada_vainilla_1kg,
            },
            {
                img: malteada_vainilla_1kg_1,
            },
            {
                img: malteada_vainilla_1kg_2,
            }
        ],
        link: 'https://www.nefropolis.com/tienda/producto/malteada-nutritiva-nin-vainilla-1-2kg?variant=7503035883295'
    },
    {
        id: 24,
        name: 'Simbiotico en polvo nin',
        description: [
            'Simbiótico NIN® es un suplemento alimenticio formulado especialmente para personas que viven con Enfermedad Renal Crónica, su composición incluye microorganismos probióticos y fibras con efectos prebióticos.'
        ],
        price: 0,
        ingredients: [
            'Inulina de agave',
            'betaglucanos de avena',
            'xilooligosacáridos',
            'mezcla de microorganismos(Lactobacillus rhamnosus, Lactobacillus acidophilus, Lactobacillus plantarum, Bifidobacterium breve, Bifidobacterium lactis, Bifidobacterium longum, Streptococcus thermophilus)',
            'dióxido de silicio',
            'fructosa',
            'extracto de arándano',
            'saborizante',
            'colorante',
            'zinc',
            'ácido fólico'
        ],
        presentation: [
            'Caja con 196 g, 14 sobres de 14 g.',
        ],
        netContent: [
            '196 g',
        ],
        principal_img: simbiotico,
        images: [
            {
                img: simbiotico,
            },
            {
                img: simbiotico_1,
            },
            {
                img: simbiotico_2,
            },
            {
                img: simbiotico_3,
            },
            {
                img: simbiotico_4,
            },
        ],
        link: 'https://www.nefropolis.com/tienda/producto/simbiotico-en-polvo-nin-sabor-arandano?variant=7503018606910'
    },
    {
        id: 25,
        name: 'Malteada de proteina vegetal sabor fresa 600g',
        description: [
            'La Malteada de proteína vegetal NIN® es un suplemento alimenticio con alto aporte de proteína de origen vegetal, así como de energía (Kcals), adicionada con inulina de agave, omega 3, vitaminas y minerales, diseñado especialmente para el consumo de personas con enfermedad renal crónica (ERC) en etapa de diálisis, sabor fresa.'
        ],
        price: 0,
        ingredients: [
            'Grasa de canola',
            'maltodextrina',
            'proteína de arroz',
            'proteína de soya',
            'grasa de palma',
            'proteína de chicharo',
            'inulina de agave',
            'saborizantes artificiales',
            'espenzante',
            'omega 3',
            'carbonato de magnesio',
            'mezcla multivitamínica(maltodextrina, ascobarto de sodio(vitamina C)',
            'sulfato de zinc',
            'niacinamida(vitamina B3)',
            'piridoxina clorhidrato(vitamina B6)',
            'pirofisfato férrico monohidratado de tiamina(vitamina B1)',
            'ácido fólico',
            '(vitamina B9)',
            'selenio de sodio',
            'ergocalciferol',
            '(vitamina D2)',
            'clanocobalamina(vitamina B12)',
            'sucralosa(30mg / 100g de producto)',
            'goma xantana',
            'colorante(rojo 40)',
            'sucralosa / 18 mg / 100 g'
        ],
        presentation: [
            'Frasco con polvo de 600 g',
        ],
        netContent: [
            '600 g',
        ],
        principal_img: malteada_vegetal_600g,
        images: [
            {
                img: malteada_vegetal_600g,
            },
            {
                img: malteada_vegetal_600g_1,
            },
            {
                img: malteada_vegetal_600g_2,
            }
        ],
        link: 'https://www.nefropolis.com/tienda/producto/proteina-vegetal-nin-fresa-600g?variant=7503018606668'
    },
    {
        id: 26,
        name: 'Extrácto de arandano nin',
        description: [
            'Suplemento alimenticio en presentación de cápsulas, que contribuye a un adecuado aporte de antioxidantes (fenoles y proantocianidina) indispensables para un correcto funcionamiento celular, así como efectos antimicrobianos.'
        ],
        price: 0,
        ingredients: [
            'PACRAN® (extracto de arándano)',
            '(vaccinium macrocarpon aiton)',
            'celulosa microcristalina',
            'estearato de magnesio'
        ],
        presentation: [
            'Caja con 30 cápsulas de 600 mg c/u',
        ],
        netContent: [
            '600 mg',
        ],
        principal_img: bote_arandano,
        images: [
            {
                img: bote_arandano,
            },
            {
                img: bote_arandano_1,
            },
            {
                img: bote_arandano_2,
            },
            {
                img: bote_arandano_3,
            },
        ],
        link: 'https://www.nefropolis.com/tienda/producto/extracto-de-arandano-nin?variant=7503018606279'
    },
    {
        id: 27,
        name: 'Malteada nutritiva sabor vainilla 490g',
        description: [
            'La malteada nutritiva NIN® es un suplemento alimenticio completo, hipercalórico por nutriólogos especializados en la enfermedad renal crónica. Por sus bajos aportes de minerales y proteínas, su consumo es ideal en la etapa de prediálisis, contribuyendo a mejorar el estado de nutrición de los pacientes con ERC.'
        ],
        price: 0,
        ingredients: [
            'Aceite de canola',
            'proteína aislada de soya',
            'almidón',
            'saborizantes',
            'aceite de palma',
            'fructanos de agave',
            'fibra de avena',
            'maltodextrina',
            'mezcla vitamínica[maltodextrina, vitamina C, zinc, niacina(vitamina B3), piridoxina(vitamina B6), hierro, tiamina(vitamina B1), ácido fólico(vitamina B9), vitamina D3, Cianocobalamina(vitamina B12)]',
            'goma xantana',
            'sucralosa(5 mg/ 100g)',
            'Bifidobacterium longum'
        ],
        presentation: [
            'Vainilla 490 g (7 porciones)',
        ],
        netContent: [
            '490 g',
        ],
        principal_img: malteada_vainilla_490,
        images: [
            {
                img: malteada_vainilla_490,
            },
            {
                img: malteada_vainilla_490_1,
            },
            {
                img: malteada_vainilla_490_2,
            }
        ],
        link: 'https://www.nefropolis.com/tienda/producto/malteada-nutritiva-nin-vainilla-490g?variant=7503018606491'
    },
    {
        id: 28,
        name: 'Multivitaminico nin 635 mg',
        description: [
            'El multivitamínico NIN® es un suplemento alimenticio formulado específicamente para personas con enfermedad renal crónica, ya sea en etapa de prediálisis o diálisis en estado de hipovitaminosis. Su fórmula incluye vitaminas y minerales consideradas de alto riesgo de déficit en la ERC.'
        ],
        price: 0,
        ingredients: [
            'Mezcla multivitamínica; maltodextrina',
            'vitamina C',
            'zinc',
            'niacina(vitamina B3)',
            'piridoxina(vitamina B6)',
            'hierro',
            'tiamina(vitamina B1)',
            'ácido fólico(vitamina B9)',
            'omega 3',
            'selenio',
            'vitamina D3',
            'cianocobalamina(vitamina B12)'
        ],
        presentation: [
            'Frasco de plástico con 60 cápsulas multivitamínicas de 635 mg.',
        ],
        netContent: [
            '635 mg',
        ],
        principal_img: multi,
        images: [
            {
                img: multi,
            },
            {
                img: multi_1,
            },
            {
                img: multi_2,
            },
            {
                img: multi_3,
            }
        ],
        link: 'https://www.nefropolis.com/tienda/producto/multivitaminico-nin-635-mg?variant=7503018606460'
    },
    {
        id: 29,
        name: 'Inulina de agave NIN',
        description: [
            'La Inulina de Agave NIN® Tequilana Weber Variedad Azul, es un tipo de fibra soluble y funciona como prebiótico natural. En presentación polvo, sin olor ni sabor, el cúal lo hace ideal para consumir solo o mezclar con su bebida favorita todos los días.'
        ],
        price: 0,
        ingredients: [
            'Inulina de agave Tequilana Weber variedad Azul en polvo. (Agavetequilana)'
        ],
        presentation: [
            'Tarro de plástico con 250 g en presentación de polvo con 41.7 porciones.',
        ],
        netContent: [
            '250 g',
        ],
        principal_img: inulina,
        images: [
            {
                img: inulina,
            },
            {
                img: inulina_1,
            },
            {
                img: inulina_2,
            },
            {
                img: inulina_3,
            },
            {
                img: inulina_4,
            },
        ],
        link: 'https://www.nefropolis.com/tienda/producto/inulina-de-agave-nin-fibra-soluble?variant=7503018606248'
    },
]