import React from 'react'

//MUI
import { IconButton, Stack } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

//Components
import Products from '../Products/Products'

const ProductsList = ({ data }) => {

  return (
    <>
      <Swiper
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          400: {
            slidesPerView: 1,
          },
          600: {
            slidesPerView: 3,
          },
          700: {
            slidesPerView: 4,
          },
          1000: {
            slidesPerView: 5,
          },
        }}
        spaceBetween={20}
        navigation={{
          nextEl: '.products-swiper-button-next',
          prevEl: '.products-swiper-button-prev',
        }}
        loop={false}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }}
        modules={[Autoplay, Navigation]}
        
      >
        {
          data.map((res) => (
            <SwiperSlide key={res.id}>
              <Products res={res} />
            </SwiperSlide>
          ))
        }
      </Swiper>

      <Stack direction='row' spacing={1.2} justifyContent="center" sx={{ mt: 1 }}>
        <IconButton className='products-swiper-button-prev' size='small'>
          <ChevronLeftIcon />
        </IconButton>
        <IconButton className='products-swiper-button-next' size='small'>
          <ChevronRightIcon />
        </IconButton>
      </Stack>
    </>
  )
}

export default ProductsList