import React, { useState, useEffect } from 'react'

//Components
import PovList from '../PovList/PovList'

//Utils
import { CustomFetch } from '../../../Utils/CustomFetch'
import { POVData } from '../../../Data/POVData'

const PovListContainer = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(false)
        CustomFetch(POVData)
            .then(res => {
                setData(res)
                setLoading(true)
            })
    }, [])

    return (
        <>
            {
                loading
                    ?
                    <PovList data={data}/>
                    :
                    <>Loading...</>
            }
        </>
    )
}

export default PovListContainer