export const POVData = [
    {
        id: 1,
        name: 'GABRIELA RAMIREZ',
        address: 'montes himalya #713 col jardines de la concepción cp 20120 aguascalientes aguas',
        city: 'AGUASCALIENTES',
        state: 'Aguascalientes',
        phone: '44 9557 5924',
        email: 'nutritioncare.ags@hotmail.com'
    },
    {
        id: 2,
        name: 'COLSULTORIO DE NUTRICION "NUTRETE"',
        address: 'calle nicolas bravo #261-2 col militar, cp. 21470',
        city: 'TECATE',
        state: 'Baja California Sur',
        phone: '66 5121 5539',
        email: []
    },
    {
        id: 3,
        name: 'MONICA SEGURA TRUJILLO',
        address: 'calle melon 175 col indeco.la paz, bcs cp.23070',
        city: 'LA PAZ',
        state: 'Baja California Sur',
        phone: [],
        email: []
    },
    {
        id: 4,
        name: 'FELIPE ERICEL HERNANDEZ ARELLANES',
        address: 'Chulavista 48, Tepeyac Insurgentes, Gustavo A. Madero, C.P. 07020 Ciudad de México, CDMX.',
        city: 'CDMX',
        state: 'Ciudad de México',
        phone: '55 4373 3526',
        email: 'felipe.hdz.arellanes@outlook.com'
    },
    {
        id: 5,
        name: 'HUGO ENRIQUE VALLE',
        address: 'calle cholultecas 11 col la raza azcapotzalco',
        city: 'CDMX',
        state: 'Ciudad de México',
        phone: '55 1656 2370',
        email: 'huyve07@gmail.com'
    },
    {
        id: 6,
        name: 'JOSE RIGOBERTO JUAREZ ALBORES',
        address: 'Callejón poniente #7 entre 3a y 4a norte colonia Terán Tuxtla Gutiérrez Chiapas cp 29050',
        city: 'TUXTLA',
        state: 'Chiapas',
        phone: '96 1181 4823',
        email: []
    },
    {
        id: 7,
        name: 'JUAN CARLOS HERRERA GASTELUM',
        address: 'Av Valentín Fuentes Varela 2571 Col. Casas Grandes, 32600 Cd Juárez, Chihuahua.',
        city: 'CD JUAREZ',
        state: 'Chihuahua',
        phone: '65 6318 3523',
        email: 'cdjuarez@nefropolis.com'
    },
    {
        id: 8,
        name: 'ELISA JAQUEZ',
        address: '2da entre rayón y aldama #607 Colonia centro CD. C. P. 31500',
        city: 'CUAHUTEMOC',
        state: 'Chihuahua',
        phone: '62 5111 2953',
        email: 'elisa.jaquez@hotmail.com'
    },
    {
        id: 9,
        name: 'ARACELI BANDA',
        address: 'calle maria curiel #101, col. estancia de san juan bautista, cp 25733',
        city: 'MONCLOVA',
        state: 'Coahuila',
        phone: '86 6630 1568 / 86 6100 9420',
        email: 'ramiro_nefrologo@hotmail.com'
    },
    {
        id: 10,
        name: 'VERONICA AGUIRRE FLORES ',
        address: 'av. allende 695 nte. colonia centro , cp. 27000',
        city: 'TORREON',
        state: 'Coahuila',
        phone: '87 1705 3366',
        email: 'osteosan.nominas@hotmail.com'
    },
    {
        id: 11,
        name: 'CHRISTIAN LARIOS',
        address: 'Avenida Paseo de las Garzas #35. Salagua Solares, Manzanillo, Colima C.P. 28869',
        city: 'COLIMA',
        state: 'Colima',
        phone: '33 1546 0653',
        email: 'christianlarios@hotmail.com'
    },
    {
        id: 12,
        name: 'CENTRO MEDICO SAN ARTE',
        address: 'genova #687, col.residencial campestre, cp. 36698',
        city: 'IRAPUATO',
        state: 'Guanajuato',
        phone: '46 2173 3131',
        email: []
    },
    {
        id: 13,
        name: 'ROMA RUBI RODRIGUEZ',
        address: 'hernan cortes #3 col guerrero chilpancingo guerrero, cp 39020',
        city: 'Chilpancingo',
        state: 'Guerrero',
        phone: '74 7122 7209',
        email: 'robyz90@hotmail.com / romarubirodriguezreyes@gmail.com'
    },
    {
        id: 14,
        name: 'AZALIA ARZIGA ',
        address: 'socrates #70 fracc marroquin c.p. 39640',
        city: 'ACAPULCO',
        state: 'Guerrero',
        phone: '74 4135 8749',
        email: 'clinicadelriñon@yahoo.com'
    },
    {
        id: 15,
        name: 'CENTRO DE ESPECIALIDADES RENALES DE CHILPACINGO',
        address: 'avenida lazaro cardenas 59 2do piso, haciendita sur, chilpancingo de los bravo. cp. 39087',
        city: 'Chilpancingo',
        state: 'Guerrero',
        phone: '74 7116 0237',
        email: 'veroportycar@hotmail.com'
    },
    {
        id: 16,
        name: 'GRUPO MÉDICO OLIMPO',
        address: 'libramiento a tampico #692 col cd santiago jaltepec, hidalgo, cp 42078',
        city: 'Cd. Santiago Jaltepec',
        state: 'Hidalgo',
        phone: '77 1318 1009',
        email: 'grupo.medico_olimpo@outlook.es / nefroin@gmail.com'
    },
    {
        id: 17,
        name: 'ILSE GABRIELA VAZQUEZ MARTINEZ',
        address: 'tierra y libertad #102 col javier rojo gomez pachuca de hidalgo soto, cp 42030 pachuca, hidalgo',
        city: 'Pachuca',
        state: 'Hidalgo',
        phone: '77 1146 6174',
        email: 'clio_87@hotmail.com / dulcecina00@hotmail.com'
    },
    {
        id: 18,
        name: 'ASCENCION DE COCULA',
        address: 'Agustín Yáñez 270 Cocula centro Cocula Jalisco CP.48500',
        city: 'COCULA',
        state: 'Jalisco',
        phone: '37 5127 0123',
        email: 'dr_sención@hotmail.com'
    },
    {
        id: 19,
        name: 'MARISA COVARRUBIAS',
        address: 'Moctezuma 4662. Jardines del Sol. Zapopan Jalisco. C. P. 45050',
        city: 'GUADALAJARA',
        state: 'Jalisco',
        phone: '33 1543 0629',
        email: []
    },
    {
        id: 20,
        name: 'COMERCIALIZADORA NUTRIMED',
        address: '',
        city: 'GUADALAJARA',
        state: 'Jalisco',
        phone: '33 1587 8376',
        email: 'tere.diaz@medintegra.mx '
    },
    {
        id: 21,
        name: 'SANVITE',
        address: 'av tepeyac 4223, prados tepeyac, zapopan jalisco c.p. 45050',
        city: 'GUADALAJARA',
        state: 'Jalisco',
        phone: '33 1987 0918',
        email: 'compras@sanvite.com'
    },
    {
        id: 22,
        name: 'CENTRO DEL RIÑON',
        address: 'avenida plan de san luis 1776, san bernardo, guadalajara jalisco, c.p. 44260',
        city: 'GUADALAJARA',
        state: 'Jalisco',
        phone: [],
        email: 'centrodelr@hotmail.com'
    },
    {
        id: 23,
        name: 'J. ARTURO RODRIGUEZ BRISEÑO',
        address: 'prol las palmas #6 col florida, cp 47820 ocotlan jalisco',
        city: 'OCOTLAN',
        state: 'Jalisco',
        phone: '39 2928 0848',
        email: 'drrodriguez_b@hotmail.com'
    },
    {
        id: 24,
        name: 'PROVISALUD',
        address: 'Aldanaca 177B local 1 , Col. Versalles, Puerto Vallarta, C.P 48310',
        city: 'PUERTO VALLARTA',
        state: 'Jalisco',
        phone: '32 2369 0057',
        email: 'farmaciaprovisalud@gmail.com'
    },
    {
        id: 25,
        name: 'DRA KATHRYNE / DR MIGUEL SERRANO',
        address: 'Av. Central Guillermo Gonzalez Camarena 911, Poniente, 45136 Zapopan, Jal. PISO 2 CONSULTORIO 8',
        city: 'ZAPOPAN',
        state: 'Jalisco',
        phone: '33 3195 0344',
        email: []
    },
    {
        id: 26,
        name: 'YADIRA MENDOZA',
        address: 'Antiguo camino a Tesistan no. 3170, Col. Nuevo México, interior 17, C, P. 45138',
        city: 'ZAPOPAN',
        state: 'Jalisco',
        phone: '33 23120 614',
        email: []
    },
    {
        id: 27,
        name: 'CLAUDIA COVARRUBIAS',
        address: 'federico del toro #364 col centro, cp 49000, zapotlan el gde',
        city: 'ZAPOTLAN EL GRANDE',
        state: 'Jalisco',
        phone: '34 1111 0804',
        email: []
    },
    {
        id: 28,
        name: 'LAURA CASTELLANOS',
        address: 'melchor ocampo 122 (esquina hidalgo) colonia centro cp 37000 león, guanajuato',
        city: 'GUANAJUATO',
        state: 'León',
        phone: '33 1185 7760',
        email: 'mariana@albadialisis.com'
    },
    {
        id: 29,
        name: 'GUSTAVO LOPEZ GASTELÚM',
        address: 'rt. loaiza #610 pte int 103 col sec fatima, cp 81200 los mochis sinaloa',
        city: 'LOS MOCHIS',
        state: 'Sinaloa',
        phone: '66 8885 9066',
        email: 'drglg@hotmail.com'
    },
    {
        id: 30,
        name: 'SELENE LOPEZ VEGA',
        address: 'ignacio allende #903 nte col centro, cp 81200 los mochis sinaloa',
        city: 'LOS MOCHIS',
        state: 'Sinaloa',
        phone: '66 8812 2017 / 74 7122 7209',
        email: 'vidalifni@hotmail.com'
    },
    {
        id: 31,
        name: 'DAVID ARRIAGA',
        address: 'gabriel tepeta #64 entre parres y sufragio efec cp. 62744 col. emiliano zapata, cuautla morelos',
        city: 'CUATLA',
        state: 'Morelos',
        phone: '77 7257 7090',
        email: 'magdalanefrologia@gmail.com'
    },
    {
        id: 32,
        name: 'NEFRO CENTER',
        address: 'av teopanzolco 213 consultorio 109, colonia vista hermosa hospital center vista hermosa cuernavaca morelos c.p. 62290',
        city: 'CUERNAVACA',
        state: 'Morelos',
        phone: '77 7135 3297',
        email: 'diana_rojm@hotmail.com'
    },
    {
        id: 33,
        name: 'MIGUEL CASTAÑON MAYO / MICAELA',
        address: 'avenida cuahutemoc 169, entre chapultepec y san juan col chapultepec c. p. 62450',
        city: 'CUERNAVACA',
        state: 'Morelos',
        phone: '77 7135 3297',
        email: 'mcmpl@hotmail.com'
    },
    {
        id: 34,
        name: 'MA DE JESUS LANGARICA',
        address: 'tepic norte 461- local 15 mezcales c. p. 63738',
        city: 'Tepic',
        state: 'Nayarit',
        phone: '32 2227 4644',
        email: 'marilang_@hotmail.com'
    },
    {
        id: 35,
        name: 'MELISSA HERMOSILLO',
        address: 'Río Suchiate 675 Colonia los fresnos oriente. C. P. 63190',
        city: 'Tepic',
        state: 'Nayarit',
        phone: '31 1910 0659',
        email: 'farmaciadvfacturacion@gmail.com'
    },
    {
        id: 36,
        name: 'ANA ISABEL GONZALEZ CARLOS',
        address: 'Av México Nte 323, San Antonio, 63159 Tepic, Nay.',
        city: 'Tepic',
        state: 'Nayarit',
        phone: '31 1134 7994',
        email: 'aigc271@hotmail.com'
    },
    {
        id: 37,
        name: 'DR VICTOR FRANCO',
        address: 'Av. Universidad #145, Cd del Valle, cp 63157, Tepic Nayariit',
        city: 'Tepic',
        state: 'Nayarit',
        phone: '31 1214 7971',
        email: 'francov61@gmail.com'
    },
    {
        id: 38,
        name: 'RENE CAZOLA',
        address: 'RAXEDIS Nº Ext 243 Nº Int B Colonia: Adolfo López Mateos, Tepic, Nayarit C.P. 63021',
        city: 'Tepic',
        state: 'Nayarit',
        phone: '31 1141 4832',
        email: 'imec.tepic@gmail.com'
    },
    {
        id: 39,
        name: 'GEOMED / ING. HERNAN ALMAGUER',
        address: 'albino espinoza #1702, col. centro, cp. 64000',
        city: 'Monterrey',
        state: 'Nuevo León',
        phone: '81 1774 4958',
        email: 'halmaguer@geomed.com.mx'
    },
    {
        id: 40,
        name: 'ADRIAN JIMENEZ MARTINEZ ',
        address: 'Calle Francisco Márquez 108 colonia el exmarquesado centro oaxaca Cp 68030',
        city: 'Oaxaca',
        state: 'Oaxaca',
        phone: '95 1186 6233',
        email: 'adrian_6330@hotmail.com'
    },
    {
        id: 41,
        name: 'JOSE LUIS SOLANO RAMIREZ',
        address: 'avenida libertad sur 107, colonia centro. san martin texmelucan. puebla. cp 74000',
        city: 'SAN MARTIN TEXMELUCAN',
        state: 'Puebla',
        phone: '22 2616 3494',
        email: 'jlsolano03@hotmail.com'
    },
    {
        id: 42,
        name: 'CBIT BETICIA MONTERO ',
        address: 'Calle 27 poniente 2905 colonia Santa Cruz Los Ángeles, puebla, c.p 72400',
        city: 'TEMACALCHALCO',
        state: 'Puebla',
        phone: '22 2848 8619',
        email: 'cbit.gerencia@gmail.com'
    },
    {
        id: 43,
        name: 'RODRIGO RAMIREZ VALENCIA  / NUTRIVIDA',
        address: 'privada vertiz 1d. mercurio, av. luis pasteur c. p. 76040',
        city: 'Querétaro',
        state: 'Querétaro',
        phone: '44 2669 4126',
        email: 'nutrividaQ@gmail.com'
    },
    {
        id: 44,
        name: 'AGUSTIN DELGADO SANDOVAL',
        address: 'super mza 523 manzana 30 calle lontue fraccionamiento san gerónimo 3 c.p 77530 cancún q.roo',
        city: 'CANCUN',
        state: 'Quintana Roo',
        phone: '98 8145 2261',
        email: 'agustin.delgado04@hotmail.com'
    },
    {
        id: 45,
        name: 'YESENIA ARAIZA GAMBOA ',
        address: '"Calle Julio Betancourt #158-B, Fracc. Virreyes, CP 78240, San Luis Potosí, SLP.',
        city: 'SAN LUIS POTOSI',
        state: 'San Luis Potosí',
        phone: '44 4213 7433',
        email: 'yeseniaaraiza.nutricion@gmail.com'
    },
    {
        id: 46,
        name: 'PAUL IVAN IBAÑEZ',
        address: 'vereda de la milpa #83 colonia fraccionamiento hacienda de las cruces.c.p. 82126',
        city: 'MAZATLAN',
        state: 'Sinaloa',
        phone: '66 9129 4123',
        email: 'paulivan_14@hotmail.com'
    },
    {
        id: 47,
        name: 'Virginia Perez',
        address: 'Zaragoza 305 C.P. 86000',
        city: 'Villahermosa',
        state: 'Tabasco',
        phone: '99 3312 0140',
        email: 'farmacias_genesis@hotmail.com'
    },
    {
        id: 48,
        name: 'ANGEL ALBERTO ESPITIA AYALA ',
        address: 'Calle 3, número 103c interior 306 colonia bonanza Villahermosa, Tabasco , Cp 86030',
        city: 'COMACALCO',
        state: 'Tabasco',
        phone: '93 3170 2025',
        email: 'aaespitia7@gmail.com'
    },
    {
        id: 49,
        name: 'AMALIA ELIZABETH TRUJILLO TORRES',
        address: 'Prolongación de Avenida México 115, colonia Sabina. Villahermosa, Tabasco. C.P. 86153. ',
        city: 'Villahermosa',
        state: 'Tabasco',
        phone: '93 3170 0106',
        email: 'amaliatrujillotorres@outlook.com'
    },
    {
        id: 50,
        name: 'HILDA ALVAREZ DEL CASTILLO',
        address: 'privada nayarit #1105 col madero c.p. 88270',
        city: 'NVO LAREDO',
        state: 'Tamaulipas',
        phone: '81 1179 9036',
        email: 'alvarezdelcastillonutricion@gmail.com'
    },
    {
        id: 51,
        name: 'ESMIRNA ROSA CHUY DIAZ',
        address: 'av hidalgo 1601, col martock',
        city: 'TAMPICO',
        state: 'Tamaulipas',
        phone: '83 3255 4339',
        email: 'esmirnachuy@yahoo.es'
    },
    {
        id: 52,
        name: 'NEYDA MARQUEZ',
        address: 'av. guadalupe victoria #9492 zona urbana río tijuana, bc.n. c.p. 22010',
        city: 'Tijuana',
        state: 'Baja California',
        phone: '66 4200 2043, 46 Y 47',
        email: 'ncelinita@hotmail.com'
    },
    {
        id: 53,
        name: 'OSCAR SANCHEZ SANCHEZ',
        address: 'carretera tlaxcala puebla #64',
        city: 'SANTA MARIA ACUITLAPILCO',
        state: 'Tlaxcala',
        phone: '24 6457 9395',
        email: 'ozkarin_nefro@hotmail.com'
    },
    {
        id: 54,
        name: 'JUAN FERNANDO CABRERA ',
        address: 'Calle Gladiolas #16 fraccionamiento rancho alegre 1, C.P. 96558',
        city: 'COATZACOALCOS',
        state: 'Veracruz',
        phone: '92 1283 5979',
        email: 'nutricionrenal@icloud.com'
    },
    {
        id: 55,
        name: 'Guisela Xiomara  Gonzalez Chong',
        address: 'Av. Sebastian Lerdo de Tejada No 904-A Col. Centro, C.P.96400, Coatzacoalcos , Ver.',
        city: 'Coatzacoalcos ',
        state: 'Veracruz',
        phone: '55 3017 3407',
        email: 'dra.gonzalez.chong@hotmail.com'
    },
    {
        id: 56,
        name: 'Farmacia Siena',
        address: 'Calle 11 No 233, col. centro C.P 94500, Cordoba, Veracruz',
        city: 'Cordoba',
        state: 'Veracruz',
        phone: '27 17143 655',
        email: 'farmaciasiena@hotmail.com'
    },
    {
        id: 57,
        name: 'Nefrofarmacia',
        address: 'Calle 14 entre Avenidas 7 y 9 , Col San Jose C. P. 94560',
        city: 'Cordoba',
        state: 'Veracruz',
        phone: '27 1104 2316',
        email: 'roytoledo@gmail.com'
    },
    {
        id: 58,
        name: 'CLAUDIA GASCA',
        address: 'Calle 47, numero 931, entre 108a y 112 Fraccionamiento Las Américas, Mérida Yucatán 97307',
        city: 'Merida',
        state: 'Yucatan',
        phone: '99 2274 6062',
        email: []
    },
    {
        id: 59,
        name: 'José Ma. Ramonet',
        address: 'Callejón de Olivarez 12. col Florida C. P. 98618',
        city: 'Guadalupe',
        state: 'Zacatecas',
        phone: '49 2493 7622',
        email: 'negro-consentido@hotmail.com'
    },
    {
        id: 60,
        name: 'SERGIO',
        address: 'Privada Bonaterre 38 C.P. 98609',
        city: 'Guadalupe',
        state: 'Zacatecas',
        phone: '49 2923 0645',
        email: 'utrzacatecas@hotmail.com'
    },
]