import React from 'react'

//Hash-link
import { HashLink } from 'react-router-hash-link';

//MUI
import { Box, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material'

const Sidebar = ({ sidebar, setSidebar, handleRef }) => {

    const closeSidebar = () => {
        setSidebar(false)
    }

    return (
        <Drawer
            open={sidebar}
            onClose={closeSidebar}
        >
            <Box
                sx={{ width: 250 }}
                role="presentation"
            >
                <List>
                    <ListItem disablePadding>
                        <ListItemButton LinkComponent={HashLink} to="/#sobre-nosotros" onClick={() => { setSidebar(false) }}>
                            <ListItemText primary="Sobre nosotros" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton LinkComponent={HashLink} to="/#productos" onClick={() => { setSidebar(false) }}>
                            <ListItemText primary="Productos" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton LinkComponent={HashLink} to="/#puntos-de-venta" onClick={() => { setSidebar(false) }}>
                            <ListItemText primary="Puntos de venta" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            handleRef()
                            setSidebar(false)
                        }}>
                            <ListItemText primary="Contacto" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    )
}

export default Sidebar