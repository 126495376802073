import { msg } from "../Helpers/msgHelper";
import { request } from "../Helpers/requestHelper";

// enviar formulario de contacto
export const contactForm = async (model) => {
    try {
        const resp = await request('form/contact', model, 'POST');
        const body = await resp.json();
        if (body.status)
            msg('success', 'Mensaje enviado',
            'Le agradecemos sus comentarios');
        else {
            if (body.msg === 'miss')
                msg('warning', 'Contacto',
                'Falta información en su mensaje');
            else if (body.msg === 'server-err')
                msg('error', 'Contacto',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else msg('warning', 'Contacto',
                'Inténtelo de nuevo, por favor');
        }
        return body;
    } catch (err) {
        msg('error', 'Contacto',
        'No se pudo enviar su mensaje');
        return { status: false };
    };
};