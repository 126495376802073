import React from 'react'

//image gallery
import ImageGallery from 'react-image-gallery';

//Router dom
import { Link } from 'react-router-dom';

//MUI
import { Box, Button, Chip, Container, Grid, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';

const NinButton = styled(Button)(({ theme }) => ({
    color: '#FFF',
    backgroundColor: '#283B49',
    '&:hover': {
        backgroundColor: '#1a2730',
    },
}));

const ProductDetail = ({ data }) => {
    return (
        <Container maxWidth='xl' sx={{ my: 10 }}>
            <Grid container spacing={6}>
                <Grid item xl={5}>
                    <ImageGallery items={data.images.map(item => ({ original: item.img, thumbnail: item.img }))}
                        showPlayButton={false} showFullscreenButton={false}
                    />
                </Grid>
                <Grid item xl={7}>
                    <Typography variant='h5' sx={{ fontWeight: 'bold' }} paragraph>{data.name}</Typography>
                    <Box sx={{ mb: 3 }}>
                        <Typography variant='body1' gutterBottom>Descripción: </Typography>
                        {
                            data.description.map((res, index) => (
                                <Typography key={index} variant='body1' sx={{ fontWeight: 'light' }}>{res}</Typography>
                            ))
                        }
                    </Box>
                    <Box sx={{ mb: 3 }}>
                        <Typography variant='body1' gutterBottom sx={{ fontWeight: '600' }}>Ingredientes: </Typography>
                        {data.ingredients.map((res, index) => (
                            <span key={index} style={{ textTransform: 'capitalize', fontSize: '16px', fontWeight: '300' }}>{(index ? ', ' : '') + res}</span>
                        ))}
                    </Box>
                    {
                        data.presentation.length > 0
                            ?
                            <Box sx={{ mb: 3 }}>
                                <Typography variant='body1' gutterBottom sx={{ fontWeight: '600' }}>Presentación: </Typography>
                                {
                                    data.presentation.map((res, index) => (
                                        <Typography key={index} variant='body1' sx={{ fontWeight: 'light' }}>{res}</Typography>
                                    ))
                                }
                            </Box>
                            :
                            null
                    }
                    <Box sx={{ mb: 3 }}>
                        <Typography variant='body1' gutterBottom>Contenido Neto: </Typography>
                        <Stack direction="row" spacing={1}>
                            {
                                data.netContent.map((res, index) => (
                                    <Chip label={res} sx={{ fontWeight: '600', px: .5 }} key={index} />
                                ))
                            }
                        </Stack>
                    </Box>
                    <Box display='flex' justifyContent='flex-start' gap='10px' flexWrap='wrap'>
                        {/* <NinButton variant='contained' sx={{ textTransform: 'none' }}>Descargar ficha</NinButton> */}
                        <NinButton
                            variant='contained'
                            sx={{ textTransform: 'none' }}
                            LinkComponent={Link}
                            to={data.link}
                            target='_blank'
                        >
                            Ver en tienda
                        </NinButton>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default ProductDetail