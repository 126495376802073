import React from 'react'

//MUI
import { Box, Typography } from '@mui/material'
import PlaceIcon from '@mui/icons-material/Place';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

const Pov = ({ res }) => {
  return (
    <>
      <Typography variant='h6' sx={{color: '#283B49', fontWeight: 'bold'}}>{res.state}</Typography>
      <Typography variant='body1'>{res.name}</Typography>
      <Box display='flex' gap='5px' mt={1}>
        <PlaceIcon sx={{color: '#CD6155'}} />
        <Typography variant='body1' sx={{ my: 'auto', textTransform: 'capitalize' }}>{res.address}</Typography>
      </Box>
      {
        res.phone.length > 0 &&
        <Box display='flex' gap='5px' mt={1}>
          <LocalPhoneIcon />
          <Typography variant='body1' sx={{ my: 'auto' }}>{res.phone}</Typography>
        </Box>
      }
      {
        res.email.length > 0 &&
        <Box display='flex' gap='5px' mt={1}>
          <EmailIcon sx={{color: '#283B49'}} />
          <Typography variant='body1' sx={{ my: 'auto' }}>
            <a href={`mailto:${res.email}`} style={{textDecoration: 'none'}}>{res.email}</a>
          </Typography>
        </Box>
      }
    </>
  )
}

export default Pov