import React from 'react'

import './Index.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

//images
import img_1 from '../../Assets/Images/soluciones_efectivas_azul.png'
import img_2 from '../../Assets/Images/expertos_en_cuidado_renal_celeste.png'
import img_3 from '../../Assets/Images/calidad_y_seguridad_azul.png'
import about from '../../Assets/Images/linea_nin_imagen.jpg'
import pv_1 from '../../Assets/Images/Mercado-Livre-Logo.png'
import pv_2 from '../../Assets/Images/Amazon-Logo.png'
import pv_3 from '../../Assets/Images/Logo_Nefropolis.png'
import banner_desktop from '../../Assets/Images/banners-web-NIN-2000x1000.jpg'
import banner_tablet from '../../Assets/Images/banners-web-NIN-1600x900.jpg'
import banner_mobile from '../../Assets/Images/banners-web-NIN-1080x920.jpg'

//MUI
import { Box, Container, Grid, Typography } from '@mui/material'

//Components
import ProductsListContainer from '../Products/ProductsListContainer/ProductsListContainer'
import PovListContainer from '../Pov/PovListContainer/PovListContainer';


const Index = () => {
  return (
    <>
      <div className='banners-desktop'>
        <img src={banner_desktop} alt={banner_desktop} width='100%' height='100%' />
      </div>

      <div className='banners-tablet'>
        <img src={banner_tablet} alt={banner_tablet} width='100%' height='100%' />
      </div>

      <div className='banners-mobile'>
        <img src={banner_mobile} alt={banner_mobile} width='100%' height='100%' />
      </div>

      <Container maxWidth='lg' sx={{ my: 10 }}>
        <Grid container spacing={4} sx={{ textAlign: 'center' }}>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12} sx={{ mx: 'auto' }}>
            <img src={img_1} alt={img_1} width='75%' style={{ pointerEvents: 'none' }} />
            <Typography variant='body1' sx={{ fontWeight: '400' }}>Soluciones efectivas y especializadas</Typography>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12} sx={{ mx: 'auto' }}>
            <img src={img_2} alt={img_2} width='75%' style={{ pointerEvents: 'none' }} />
            <Typography variant='body1' sx={{ fontWeight: '400' }}>Expertos en el cuidado Renal</Typography>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12} sx={{ mx: 'auto' }}>
            <img src={img_3} alt={img_3} width='75%' style={{ pointerEvents: 'none' }} />
            <Typography variant='body1' sx={{ fontWeight: '400' }}>Calidad y Seguridad</Typography>
          </Grid>
        </Grid>
      </Container>

      <div id='sobre-nosotros' style={{ marginTop: '20px' }}></div>

      <Container maxWidth='xl' sx={{ my: 10 }}>
        <Grid container spacing={4}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ my: 'auto' }}>
            <Typography variant='h4' sx={{ fontWeight: '600' }} paragraph>Linea renal NIN</Typography>
            <Typography variant='body1' paragraph>
              Los productos NIN, son parte de la línea de productos (alimentos, alimentos funcionales y suplementos) desarrollados por NIN Institute, especialmente
              para cubrir las necesidades de personas con enfermedad renal crónica.
            </Typography>
            <Typography variant='body1' paragraph>
              Nuestra misión es proporcionar opciones nutricionales de alta calidad que respalden las necesidades específicas de las personas con enfermedad renal
              crónica. Reconocemos que cada individuo es único, y por eso hemos trabajado arduamente para crear productos que sean no solo efectivos, sino también
              deliciosos y variados.
            </Typography>
            <Typography variant='body1' paragraph>
              Cada producto NIN está formulado con ingredientes seleccionados cuidadosamente para brindar el equilibrio adecuado de nutrientes y sabores. Nuestro
              compromiso con la calidad es inquebrantable, y trabajamos incansablemente para asegurarnos de que cada producto cumpla con los estándares más exigentes.
            </Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ my: 'auto' }}>
            <img src={about} alt={about} width='100%' style={{ borderRadius: '5px' }} />
          </Grid>
        </Grid>
      </Container>

      <div id='productos' style={{ marginTop: '20px' }}></div>

      <Container maxWidth='xxl' sx={{ my: 10 }}>
        <Typography sx={{ fontSize: '30px', fontWeight: '600', mb: 4 }} align='center'>Nuestros productos</Typography>
        <ProductsListContainer />
      </Container>

      <div id='puntos-de-venta' style={{ marginTop: '20px' }}></div>

      <Box sx={{ my: 10, bgcolor: '#EFEFEF', py: 5 }} id='productos'>
        <Container maxWidth='xl' className='index_pv'>
          <Typography sx={{ fontSize: '32px', fontWeight: 'bold' }} align='center'>Puntos de venta</Typography>
          <Swiper
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              400: {
                slidesPerView: 1,
              },
              600: {
                slidesPerView: 2,
              },
              830: {
                slidesPerView: 2,
              },
              1000: {
                slidesPerView: 3,
              },
            }}
            spaceBetween={20}
            navigation={true}
            loop={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            }}
            modules={[Autoplay, Navigation]}
            style={{ textAlign: 'center' }}
          >
            <SwiperSlide style={{ margin: 'auto 0 auto 0' }}>
              <img src={pv_1} alt="" width='250' height='250' style={{ objectFit: 'contain' }} />
            </SwiperSlide>

            <SwiperSlide style={{ margin: 'auto 0 auto 0' }}>
              <img src={pv_2} alt="" width='250' height='250' style={{ objectFit: 'contain' }} />
            </SwiperSlide>

            <SwiperSlide style={{ margin: 'auto 0 auto 0' }}>
              <a href='https://www.nefropolis.com/tienda/' target='_blank' rel="noopener noreferrer">
                <img src={pv_3} alt="" width='250' height='250' style={{ objectFit: 'contain' }} />
              </a>
            </SwiperSlide>
          </Swiper>
        </Container>
      </Box>

      <Container maxWidth='lg' sx={{ my: 10 }}>
        <PovListContainer />
      </Container>
    </>
  )
}

export default Index