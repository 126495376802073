import React from 'react'

//Router dom
import { Link } from 'react-router-dom'

//MUI
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material'

const Products = ({ res }) => {
  return (
    <Card sx={{ width: '100%', border: 0, boxShadow: 'none' }}>
      <CardActionArea LinkComponent={Link} to={`/producto/${res.name}`}>
        <CardMedia
          component="img"
          sx={{ backgroundSize: 'contain' }}
          image={res.principal_img}
          alt={res.principal_img}
        />
        <CardContent>
          <Typography variant='body1' align='center'>{res.name}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default Products