import React, { useState } from 'react'

//Hash-link && router dom
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

//Images
import logo from '../../Assets/Images/Logo_NIN-02.png'

//MUI
import { AppBar, Box, Button, IconButton, Toolbar } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';


//Components
import Sidebar from './Sidebar/Sidebar';

const Header = ({ handleRef }) => {

    //Sidebar
    const [sidebar, setSidebar] = useState(false)
    const handleSidebar = () => {
        setSidebar(true)
    }

    return (
        <>
            <AppBar position="sticky" sx={{ bgcolor: '#ffffff' }}>
                <Toolbar>
                    <Box sx={{ display: { xl: 'none', lg: 'none', md: 'none', sm: 'block', xs: 'block' } }}>
                        <IconButton
                            size="large"
                            edge="start"
                            sx={{ color: 'black', fontWeight: '600', mr: 2 }}
                            onClick={handleSidebar}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: { xl: 'flex-start', lg: 'flex-start', md: 'flex-start', sm: 'flex-end', xs: 'flex-end' } }}>
                        <Link to='/'>
                            <img src={logo} alt={logo} width='80' />
                        </Link>
                    </Box>
                    <Box sx={{ display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none' }, gap: '12px' }}>
                        <Button sx={{ color: 'black', fontWeight: '600', textTransform: 'none' }} LinkComponent={HashLink} to="/#sobre-nosotros">Sobre nosotros</Button>
                        <Button sx={{ color: 'black', fontWeight: '600', textTransform: 'none' }} LinkComponent={HashLink} to="/#productos">Productos</Button>
                        <Button sx={{ color: 'black', fontWeight: '600', textTransform: 'none' }} LinkComponent={HashLink} to="/#puntos-de-venta">Puntos de venta</Button>
                        <Button sx={{ color: 'black', fontWeight: '600', textTransform: 'none' }} onClick={handleRef}>Contacto</Button>
                    </Box>
                </Toolbar>
            </AppBar>

            <Sidebar sidebar={sidebar} setSidebar={setSidebar} handleRef={handleRef} />
        </>
    )
}

export default Header